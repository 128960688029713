import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import styled from "styled-components"
import { isMobile } from "react-device-detect";

import AccountPage from "../../components/layout/AccountPage"

import { postData } from "../../services/server"
import { formatPrice } from "../../utilities/utils"

const Orders = styled.table`
    width: 100%;

    tr {
        cursor: pointer;

        &:hover {
            td {
                background-color: #e0e0e0;
            }
        }
    }
    th {
        padding: 5px;
    }
    td {
        padding: 8px;
        background-color: white;
    }

    div.balance {
        color: red;
    }
`

const AccountOrdersPage = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  useEffect(
    () => {
        setLoading(true);

        postData("/api/orders/foruser", {},
            function(result) {
                setOrders(result);
            },
            function(error,status) {
                // if (status == 401 || status == 403)
                //     navigate("/account")
                // else
                    setError(error);
            },
            function() {
                setLoading(false);
            }
        );
      },
    []
  );

  return (
    <AccountPage 
        page="orders" 
		breadcrumb={[
			{url: "/", title: "Home"},
			{url: null, title: "Orders"}
		]}
        secure="registered" 
        loading={loading} 
        error={error}
    >
      { orders.length ? 
            <Orders>
                <thead>
                    <tr>
                        <th>Order #</th>
                        <th>Date Ordered</th>
                        {!isMobile && <th>Items</th>}
                        <th align="right">Total</th>
                        {!isMobile && <th>Status</th>}
                    </tr>
                </thead>
                <tbody>
                {orders.map(order => (
                    <tr key={order.id} onClick={() => navigate("/account/order/?uid=" + order.uid)}>
                        <td>
                            <Link to={"/account/order/?uid=" + order.uid} onClick={() => false}>
                                #{order.id}
                            </Link>
                        </td>
                        <td>
                            {order.order_date_formatted}
                        </td>
                        {!isMobile && <td>{order.item_count}</td> }
                        <td align="right">
                            {formatPrice(order.total)}
                            {(order.balance && order.balance > 0) &&
                                <div className="balance">
                                    Balance: {formatPrice(order.balance)}
                                </div> 
                            }
                        </td>
                        {!isMobile && <td>{order.status_title}</td> }
                    </tr>
                ))}
                </tbody>
            </Orders>
        :
            <p>No orders found.</p>
      }
    </AccountPage>
  )
}

export default AccountOrdersPage
